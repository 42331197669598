'use client'

import * as styles from './NavBar.css'
import { type ReactElement, useEffect, useState } from 'react'
import { usePathname } from 'next/navigation'
import { useScrollbarOffset } from '@/hooks/useScrollLock'
import { isGhostStore } from './NavBarSearch'
import { useIsTop } from './hooks'

export default function NavBarWrapper ({ children }: { children: ReactElement }) {
  const isTop = useIsTop()
  const isHome = useIsHome()
  const ghost = isHome && isTop

  useEffect(() => {
    isGhostStore.set(ghost)
  }, [ghost])

  return (
    <nav
      className={`${styles.root} ${ghost ? styles.ghost : ''} ${isHome ? styles.isHome : ''}`}
      style={{
        marginRight: useScrollbarOffset(),
      }}
    >
      {children}
    </nav>
  )
}

function useIsHome () {
  const pathname = usePathname()
  const [isHome, setIsHome] = useState(pathname === '/')
  useEffect(() => {
    setIsHome(pathname === '/')
  }, [pathname])
  return isHome
}
