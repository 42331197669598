'use client'

import { type ReactNode } from 'react'
import { darkThemeClass, lightThemeClass } from '@/styles/theme.css'
import { ThemeProvider } from 'next-themes'
import { SWRConfig } from 'swr'
import { MediaContextProvider } from '../MediaQuery/MediaQuery'
import { ClerkProvider } from '@clerk/nextjs'
import { deDE } from '@clerk/localizations'

const fetcher = async url => await fetch(url).then(async r => await r.json())

export default function Providers ({ children }: { children: ReactNode }) {
  return (
    <SWRConfig value={{ fetcher }}>
      <MediaContextProvider>
        <ThemeProvider
          attribute='class'
          enableSystem={false}
          value={{
            light: lightThemeClass,
            dark: darkThemeClass,
          }}
        >
          <ClerkProvider
            localization={deDE}
            supportEmail='hallo@gesetzefinden.at'
            appearance={{
              layout: {
                termsPageUrl: '/agb',
                privacyPageUrl: '/datenschutz',
              },
            }}
          >
            {children}
          </ClerkProvider>
        </ThemeProvider>
      </MediaContextProvider>
    </SWRConfig>
  )
}
