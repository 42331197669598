'use client'

import { checkFlexGapSupport } from '@christiankaindl/lyts'
import { useEffect } from 'react'

/**
 * Initialize client effects
 */
export default function Init () {
  useEffect(() => {
    checkFlexGapSupport()
  }, [])

  return null
}
