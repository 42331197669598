'use client'

import Script from 'next/script'
import { env } from '@/env'

/**
* Insert Matomo Tag Manager to track page views
* @docs https://developer.matomo.org/guides/spa-tracking
*/
export default function Matomo () {
  /*
    * Load script here, as onLoad/onReady/onError does not fire for afterInteractive in layout.tsx
    * See https://github.com/vercel/next.js/issues/47666
    */
  return (
    <Script
      id='matomo-container'
      src={`https://cdn.matomo.cloud/gesetzefinden.matomo.cloud/container_aEvKBgvb${env.NEXT_PUBLIC_VERCEL_ENV !== 'production' ? '_dev_690b41c73c4ef3b0339f19f5' : ''}.js?v3`}
      strategy='afterInteractive'
      onLoad={(event) => {
        // @ts-expect-error global var
        window.resolveMtmReady?.()
      }}
      onError={(error) => {
        console.log('Could not load Matomo script:', error)
        // @ts-expect-error global var
        window.resolveMtmReady?.()
      }}
    />
  )
}
